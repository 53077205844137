export function getPriceTier({
   priceTiers,
   storeCode,
}: { priceTiers: Record<string, string>; storeCode: string }) {
   const priceTier = priceTiers[storeCode] ?? null;
   return {
      priceTier,
      isProUser: Boolean(priceTier?.match(/pro/i)),
   };
}
