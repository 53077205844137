import type { User } from '@ifixit/auth-sdk';
import { type IFixitAPIClient, useIFixitApiClient } from '@ifixit/ifixit-api-client';
import { SentryError } from '@ifixit/sentry';
import {
   type StorefrontClient,
   useShopifyStorefrontClient,
} from '@ifixit/shopify-storefront-client';
import { useCallback } from 'react';
import { getStoredCustomerAccessToken, updateCustomerAccessToken } from '../helpers/storage';

/**
 * It's possible that the stored customer access token is expired. In that case,
 * the caller should clear the stored access token, generate a new one with this
 * hook's callback, and try again.
 */
export function useGetCustomerAccessToken() {
   const { client: storefrontClient, storeCode } = useShopifyStorefrontClient();
   const ifixitApiClient = useIFixitApiClient();
   return useCallback(
      async (user: User) => {
         const storedToken = getStoredCustomerAccessToken({ user, storeCode });
         if (storedToken && new Date(storedToken.expiresAt) > new Date()) {
            return storedToken;
         }
         const customerAccessToken = await createCustomerAccessToken({
            ifixitApiClient,
            storeCode,
            storefrontClient,
         });
         updateCustomerAccessToken({ customerAccessToken, storeCode, userId: user.id });
         return customerAccessToken;
      },
      [ifixitApiClient, storefrontClient, storeCode]
   );
}

type CreateCustomerAccessTokenProps = {
   ifixitApiClient: IFixitAPIClient;
   storeCode: string;
   storefrontClient: StorefrontClient;
};
async function createCustomerAccessToken({
   ifixitApiClient,
   storeCode,
   storefrontClient,
}: CreateCustomerAccessTokenProps) {
   const multipassToken = await fetchLoggedInMultipassToken({ ifixitApiClient, storeCode });
   const { customerAccessTokenCreateWithMultipass } =
      await storefrontClient.createCustomerAccessTokenWithMultipass({ multipassToken });
   if (customerAccessTokenCreateWithMultipass?.customerUserErrors?.length) {
      throw new SentryError('User errors when creating customer access token', {
         extra: { response: customerAccessTokenCreateWithMultipass },
      });
   }
   const customerAccessToken = customerAccessTokenCreateWithMultipass?.customerAccessToken;
   if (!customerAccessToken) {
      throw new SentryError('Failed to create customer access token', {
         extra: { response: customerAccessTokenCreateWithMultipass },
      });
   }
   return customerAccessToken;
}

type FetchMultipassTokenProps = {
   ifixitApiClient: IFixitAPIClient;
   storeCode: string;
};
async function fetchLoggedInMultipassToken({
   ifixitApiClient,
   storeCode,
}: FetchMultipassTokenProps) {
   const response = await ifixitApiClient.post<{ multipassToken: string }>(
      `store/user/multipass?storeCode=${encodeURIComponent(storeCode)}`,
      'post-multipass-token'
   );
   return response.multipassToken;
}
