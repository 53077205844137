import type { User } from '@ifixit/auth-sdk';
import { captureException, SentryError } from '@ifixit/sentry';
import type { CustomerAccessToken } from '@ifixit/shopify-storefront-client';
import { safeLocalStorage } from '@ifixit/utils';
import Cookies from 'js-cookie';
import { z } from 'zod';

export function getStoredCustomerAccessToken({
   storeCode,
   user,
}: { storeCode: string; user: User }): CustomerAccessToken | null {
   const userId = user.id;
   const cookieToken = getCustomerAccessToken({ storeCode, userId });
   if (cookieToken) {
      return cookieToken;
   }
   const STORAGE_KEY = customerAccessTokenStorageKey(user.id, storeCode);
   try {
      const stored = safeLocalStorage.getJson(STORAGE_KEY) as CustomerAccessToken;
      updateCustomerAccessToken({ customerAccessToken: stored, storeCode, userId });
      clearLocalStorageCustomerAccessToken({ storeCode, userId });
      return stored;
   } catch (error) {
      console.error(error);
      captureException(
         new SentryError('Failed to parse stored customer access token json', { extra: { error } })
      );
      return null;
   }
}

export function clearStoredCustomerAccessToken({
   storeCode,
   user,
}: { storeCode: string; user: User }): void {
   const userId = user.id;
   clearLocalStorageCustomerAccessToken({ storeCode, userId });
   removeCustomerAccessToken({ storeCode, userId });
}

function clearLocalStorageCustomerAccessToken({
   storeCode,
   userId,
}: { storeCode: string; userId: number }) {
   const STORAGE_KEY = customerAccessTokenStorageKey(userId, storeCode);
   safeLocalStorage.removeItem(STORAGE_KEY);
}

function customerAccessTokenStorageKey(userId: number, storeCode: string) {
   return `shopify.${storeCode}.user.${userId}.customerAccessToken`;
}

export function getStoredCartId({
   user,
   storeCode,
}: { user: User | null; storeCode: string }): string | null {
   const userId = user?.id ?? null;
   const cookieCartId = getCartId({ storeCode, userId });
   if (cookieCartId) {
      return cookieCartId;
   }
   const STORAGE_KEY = cartIdStorageKey(userId, storeCode);
   const storedCartId = safeLocalStorage.getItem(STORAGE_KEY);
   if (storedCartId) {
      updateCartId({ cartId: storedCartId, storeCode, userId });
      clearLocalStorageCartId({ storeCode, userId });
   }
   return storedCartId;
}

export function clearStoredCartId({
   storeCode,
   user,
}: { storeCode: string; user: User | null }): void {
   const userId = user?.id ?? null;
   clearLocalStorageCartId({ storeCode, userId });
   removeCartId({ storeCode, userId });
}

function clearLocalStorageCartId({
   storeCode,
   userId,
}: { storeCode: string; userId: number | null }) {
   const STORAGE_KEY = cartIdStorageKey(userId, storeCode);
   safeLocalStorage.removeItem(STORAGE_KEY);
}

function cartIdStorageKey(userId: number | null, storeCode: string) {
   return `shopify.${storeCode}.user.${userId ?? 'anonymous'}.cartId`;
}

type StoreCodeMaybeUserId = {
   storeCode: string;
   userId: number | null;
};
type StoreCodeUserId = {
   storeCode: string;
   userId: number;
};

function getCartId({ storeCode, userId }: StoreCodeMaybeUserId) {
   const cartCookie = getCartCookie({ storeCode, userId });
   return cartCookie[storeCode]?.cartId ?? null;
}

function getCustomerAccessToken({ storeCode, userId }: StoreCodeUserId) {
   const cartCookie = getCartCookie({ storeCode, userId });
   return cartCookie[storeCode]?.customerAccessToken ?? null;
}

function removeCartId({ storeCode, userId }: StoreCodeMaybeUserId) {
   const cartCookie = getCartCookie({ storeCode, userId });
   if (cartCookie[storeCode].cartId) {
      cartCookie[storeCode].cartId = null;
      setCartCookie({ cartCookie, userId });
   }
}

function removeCustomerAccessToken({ storeCode, userId }: StoreCodeUserId) {
   const cartCookie = getCartCookie({ storeCode, userId });
   if (cartCookie[storeCode].customerAccessToken) {
      cartCookie[storeCode].customerAccessToken = null;
      setCartCookie({ cartCookie, userId });
   }
}

export function updateCartId({
   cartId,
   storeCode,
   userId,
}: { cartId: string } & StoreCodeMaybeUserId) {
   const cartCookie = getCartCookie({ storeCode, userId });
   cartCookie[storeCode].cartId = cartId;
   setCartCookie({ cartCookie, userId });
}

export function updateCustomerAccessToken({
   customerAccessToken,
   storeCode,
   userId,
}: { customerAccessToken: CustomerAccessToken } & StoreCodeUserId) {
   const cartCookie = getCartCookie({ storeCode, userId });
   cartCookie[storeCode].customerAccessToken = customerAccessToken;
   setCartCookie({ cartCookie, userId });
}

const CustomerAccessTokenSchema = z.object({
   accessToken: z.string(),
   expiresAt: z.string(),
});
const CartCookieSchema = z.record(
   z.string(),
   z.object({
      cartId: z.string().nullable(),
      customerAccessToken: CustomerAccessTokenSchema.nullable(),
   })
);
type CartCookie = z.infer<typeof CartCookieSchema>;

function getCartCookie({ storeCode, userId }: StoreCodeMaybeUserId) {
   const key = cartCookieKey({ userId });
   let rawCookie;
   try {
      rawCookie = JSON.parse(Cookies.get(key) ?? '');
   } catch {
      rawCookie = null;
   }
   const parsed = CartCookieSchema.safeParse(rawCookie);
   const existing = parsed.success ? parsed.data : {};
   if (!existing[storeCode]) {
      existing[storeCode] = {
         cartId: null,
         customerAccessToken: null,
      };
   }
   return existing;
}

function setCartCookie({ cartCookie, userId }: { cartCookie: CartCookie; userId: number | null }) {
   const key = cartCookieKey({ userId });
   const domain: string | undefined = ifixitDomain() ?? cominorDomain() ?? undefined;
   // Carts live for 10 days https://shopify.dev/docs/storefronts/headless/building-with-the-storefront-api/cart#limitations-and-considerations
   const expires = 10;
   Cookies.set(key, JSON.stringify(cartCookie), { domain, expires });
}

/**
 * Keep in sync with ShopifyCustomerService.php
 */
function cartCookieKey({ userId }: { userId: number | null }) {
   return `shopifyCart.user.${userId ?? 'anonymous'}`;
}

function cominorDomain() {
   return /\.cominor.com$/.test(window.location.hostname) ? '.cominor.com' : null;
}

function ifixitDomain() {
   return /\.ifixit.com$/.test(window.location.hostname) ? '.ifixit.com' : null;
}
