import { useToast } from '@chakra-ui/react';
import {
   convertCartLineItemsToAnalyticsItem,
   trackInAnalyticsAddToCart,
   trackInAnalyticsRemoveFromCart,
   trackPiwikCartUpdate,
} from '@ifixit/analytics';
import { SentryError } from '@ifixit/sentry';
import { useShopifyStorefrontClient } from '@ifixit/shopify-storefront-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateCartLines } from '../../helpers/storefront-api';
import { buildIfixitCart } from '../../models/cart';
import type { Cart, CartLineItem } from '../../types';
import { cartKeys } from '../../utils';
import { useCart } from './use-cart';

export type UpdateLineItemQuantityInput = {
   line: CartLineItem;
   quantityDelta: number;
};

export function useUpdateLineItemQuantity() {
   const cart = useCart().data;
   const queryClient = useQueryClient();
   const { client: storefrontClient, currencyCode } = useShopifyStorefrontClient();
   const toast = useToast();
   const mutation = useMutation(
      async ({ line, quantityDelta }: UpdateLineItemQuantityInput) => {
         if (cart == null || !cart.shopifyCartId || !line?.shopifyLineId) {
            return buildIfixitCart({ cart: null, fallbackCurrencyCode: currencyCode });
         }
         return buildIfixitCart({
            cart: await updateCartLines(storefrontClient, {
               cartId: cart.shopifyCartId,
               lines: [{ id: line.shopifyLineId, quantity: line.quantity + quantityDelta }],
            }),
            fallbackCurrencyCode: currencyCode,
         });
      },
      {
         onMutate: async ({ line, quantityDelta }: UpdateLineItemQuantityInput) => {
            await queryClient.cancelQueries(cartKeys.cart);

            const previousCart = queryClient.getQueryData<Cart>(cartKeys.cart);

            queryClient.setQueryData<Cart | undefined>(cartKeys.cart, current => {
               if (current == null) {
                  return current;
               }
               const updatedItem = current.lineItems.find(item => item.itemcode === line.itemcode);
               if (updatedItem == null) {
                  return current;
               }
               const updatedItemsCount = Math.max(current.totals.itemsCount + quantityDelta, 0);
               const updateTotalPrice = Math.max(
                  Number(current.totals.price.amount) + quantityDelta * Number(line.price.amount),
                  0
               ).toFixed(2);
               return {
                  ...current,
                  hasItemsInCart: updatedItemsCount > 0,
                  lineItems: current.lineItems.map(lineItem => {
                     if (lineItem.itemcode === line.itemcode) {
                        const updatedQuantity = Math.max(lineItem.quantity + quantityDelta, 0);
                        return {
                           ...lineItem,
                           quantity: updatedQuantity,
                        };
                     }
                     return lineItem;
                  }),
                  totals: {
                     ...current.totals,
                     price: {
                        ...current.totals.price,
                        amount: updateTotalPrice,
                     },
                     itemsCount: updatedItemsCount,
                  },
               };
            });

            return { previousCart };
         },
         onError: (error, variables, context) => {
            queryClient.setQueryData<Cart | undefined>(cartKeys.cart, context?.previousCart);
            toast.closeAll();
            if (
               error instanceof SentryError &&
               Array.isArray(error.sentryDetails.extra?.validationErrors)
            ) {
               error.sentryDetails.extra.validationErrors.forEach(error =>
                  toast({
                     id: 'update-cart-error',
                     status: 'error',
                     title: error,
                     isClosable: true,
                     variant: 'subtle',
                     position: 'bottom-right',
                  })
               );
            }
         },
         onSuccess: (cart, { line, quantityDelta }) => {
            const analyticsItems = convertCartLineItemsToAnalyticsItem([line]);
            const addOrRemoveAnalyticsItems = analyticsItems.map(item => ({
               ...item,
               quantity: Math.abs(quantityDelta),
            }));
            const trackChangeInCart =
               quantityDelta < 0 ? trackInAnalyticsRemoveFromCart : trackInAnalyticsAddToCart;
            trackChangeInCart({
               items: addOrRemoveAnalyticsItems,
               value: Number(line.price.amount),
               currency: line.price.currencyCode,
            });
            trackPiwikCartUpdate({
               items: convertCartLineItemsToAnalyticsItem(cart.lineItems),
               value: Number(cart.totals.price.amount),
               currency: cart.totals.price.currencyCode,
            });
         },
         onSettled: () => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME: Promises must be awaited, end with...
            queryClient.invalidateQueries(cartKeys.cart);
         },
      }
   );
   return { updateLineItemQuantity: mutation, enabled: cart != null };
}
