import { useToast } from '@chakra-ui/react';
import {
   convertCartLineItemsToAnalyticsItem,
   trackInAnalyticsRemoveFromCart,
   trackPiwikCartUpdate,
} from '@ifixit/analytics';
import { SentryError } from '@ifixit/sentry';
import { useShopifyStorefrontClient } from '@ifixit/shopify-storefront-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { removeCartLines } from '../../helpers/storefront-api';
import { buildIfixitCart } from '../../models/cart';
import type { Cart, CartLineItem } from '../../types';
import { cartKeys } from '../../utils';
import { useCart } from './use-cart';
import { useTranslations } from '@ifixit/i18n';

export type RemoveLineItemInput = {
   line: CartLineItem;
};

export function useRemoveLineItem() {
   const cart = useCart().data;
   const queryClient = useQueryClient();
   const { client: storefrontClient, currencyCode } = useShopifyStorefrontClient();
   const toast = useToast();
   const t = useTranslations();
   const mutation = useMutation(
      async ({ line }: RemoveLineItemInput) => {
         if (cart == null || !cart.shopifyCartId || !line?.shopifyLineId) {
            return buildIfixitCart({ cart: null, fallbackCurrencyCode: currencyCode });
         }
         return buildIfixitCart({
            cart: await removeCartLines(storefrontClient, {
               cartId: cart.shopifyCartId,
               lineIds: [line.shopifyLineId],
            }),
            fallbackCurrencyCode: currencyCode,
         });
      },
      {
         onMutate: async (input: RemoveLineItemInput) => {
            await queryClient.cancelQueries(cartKeys.cart);

            const previousCart = queryClient.getQueryData<Cart>(cartKeys.cart);

            queryClient.setQueryData<Cart | undefined>(cartKeys.cart, current => {
               if (current == null) {
                  return current;
               }
               const deletedItem = current.lineItems.find(
                  item => item.itemcode === input.line.itemcode
               );
               if (deletedItem == null) {
                  return current;
               }
               const updatedItemCount = Math.max(
                  current.totals.itemsCount - deletedItem.quantity,
                  0
               );
               const updateTotalPrice = Math.max(
                  Number(current.totals.price.amount) -
                     deletedItem.quantity * Number(deletedItem.price.amount),
                  0
               ).toFixed(2);
               return {
                  ...current,
                  hasItemsInCart: updatedItemCount > 0,
                  isEmpty: updatedItemCount === 0,
                  lineItems: current.lineItems.filter(
                     product => product.itemcode !== input.line.itemcode
                  ),
                  totals: {
                     ...current.totals,
                     price: {
                        ...current.totals.price,
                        amount: updateTotalPrice,
                     },
                     itemsCount: updatedItemCount,
                  },
               };
            });

            return { previousCart };
         },
         onError: (error, variables, context) => {
            queryClient.setQueryData<Cart | undefined>(cartKeys.cart, context?.previousCart);
            toast.closeAll();
            if (
               error instanceof SentryError &&
               Array.isArray(error.sentryDetails.extra?.validationErrors) &&
               error.sentryDetails.extra.validationErrors.length > 0
            ) {
               error.sentryDetails.extra.validationErrors.forEach(error =>
                  toast({
                     id: 'remove-line-item-error',
                     status: 'error',
                     title: error,
                     isClosable: true,
                     variant: 'subtle',
                     position: 'bottom-right',
                  })
               );
            } else {
               toast({
                  id: 'remove-line-item-error',
                  status: 'error',
                  title: t('ErrorHandling.unableToRemoveProduct'),
                  description: t('ErrorHandling.tryActionAgain'),
                  isClosable: true,
                  variant: 'subtle',
                  position: 'bottom-right',
               });
            }
         },
         onSuccess: (cart, { line }) => {
            trackInAnalyticsRemoveFromCart({
               items: convertCartLineItemsToAnalyticsItem([line]),
               value: line.quantity * Number(line.price.amount),
               currency: line.price.currencyCode,
            });
            trackPiwikCartUpdate({
               items: convertCartLineItemsToAnalyticsItem(cart.lineItems),
               value: Number(cart.totals.price.amount),
               currency: cart.totals.price.currencyCode,
            });
         },
         onSettled: () => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- FIXME: Promises must be awaited, end with...
            queryClient.invalidateQueries(cartKeys.cart);
         },
      }
   );
   return { removeLineItem: mutation, enabled: cart != null };
}
